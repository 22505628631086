import React from 'react'

export default function LoadingScreen() {
  return (
    <div id='loadingScreen'>
        <div id='loader'></div>
        <div className='loadingText'><h1>Loading</h1></div>
    </div>
  )
}
